html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Open Sans', sans-serif !important;
    line-height: 1.5;
    height: 100%;
    background-color: #FFFFFF;
    color: #000000;
    overflow-x: hidden;
}

p {
    font-family: 'Open Sans', sans-serif !important;
    margin: 0;
    font-size: 1.1rem;
}

.text-justify {
    text-align: justify;
}

.text_white {
    color: #FFFFFF;
}

.bg_white {
    background-color: #FFFFFF;
}

.bg_grey {
    background-color: #ECECEC;
}

.bg_blue {
    background-color: #042048;
}

.text_blue {
    color: #44B2E2;
}

.text_grey {
    color: #9B9B9B;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
    max-width: 1200px !important;
}

.container.project,
.container-lg.project,
.container-md.project,
.container-sm.project,
.container-xl.project,
.container-xxl.project {
    max-width: 1300px !important;
}

.heading_big {
    font-size: 250%;
}

.text_big {
    font-size: 130%;
}

.h-30 {
    min-height: 30vh;
}

/* SCROLL BAR */

::-webkit-scrollbar {
    width: 10px;
    background-color: none;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: linear-gradient(180deg, rgba(52, 153, 255, 1) 0%, rgba(55, 103, 191, 1) 50%, rgba(58, 57, 133, 1) 100%) !important;
}

@-moz-document url-prefix() {
    .scroller {
        /* scrollbar-color: #3499ff #3a3985; */
        scrollbar-color: #3a3985 #3a3985;
        scrollbar-width: thin;
    }
}

/* SCOLL TO TOP */

.scroll_top_icon {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    color: #FFFFFF;
    z-index: 11;
    opacity: 0;
    width: inherit !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.scroll_top_icon.active {
    opacity: 1;
}

/* HEADER */

header a,
.book a {
    color: #FFFFFF !important;
    padding: 0 1rem;
    text-decoration: none;
    text-transform: capitalize;
    width: fit-content;
    margin-bottom: 1rem;
}

header button.contact_button {
    margin-bottom: 1rem;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.navbar-toggler {
    margin-bottom: 0;
}

@media screen and (min-width: 992px) {
    header .fixed-lg-top {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1030;
    }

    header button.contact_button,
    header a {
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 992px) {

    header button.contact_button,
    header a {
        color: #000000 !important;
    }

    header .line {
        background-color: #000000 !important;
    }

    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    }
}

header a:hover,
.book a:hover {
    text-decoration: none;
}

header .bg-light {
    background-color: transparent !important;
}

header .bg_header {
    background-color: transparent !important;
    box-shadow: 0px;
    -webkit-transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
    transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
}

header .bg_header.active {
    background-color: #FFFFFF !important;
    box-shadow: 0 1px 1px 0 rgb(140 140 140 / 15%);
    -webkit-transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
    transition: background-color 0.5s ease-out, box-shadow 0.5s ease-out;
}

header .active a,
header .active button {
    color: #000000 !important;
    -webkit-transition: color 0.5s ease-out;
    -moz-transition: color 0.5s ease-out;
    -o-transition: color 0.5s ease-out;
    transition: color 0.5s ease-out;
}

header .line {
    content: '';
    height: 2px;
    background: #ffffff;
    margin-left: auto;
    margin-right: auto;
    display: block;
    top: 2px;
    position: relative;
    -webkit-animation: scale-out-horizontal 0.5s both;
    animation: scale-out-horizontal 0.5s both;
    opacity: 0;
}

header a:hover .line,
header button.contact_button:hover .line {
    opacity: 1;
    -webkit-animation: scale-in-hor-center 0.5s both;
    animation: scale-in-hor-center 0.5s both;
}

header a.active_page .line {
    opacity: 1;
    -webkit-animation: scale-in-hor-center 0.5s both;
    animation: scale-in-hor-center 0.5s both;
}

header .active .line {
    background-color: #000000;
    -webkit-transition: background-color 0.5s ease-out;
    -moz-transition: background-color 0.5s ease-out;
    -o-transition: background-color 0.5s ease-out;
    transition: background-color 0.5s ease-out;
}

@-webkit-keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

@keyframes scale-in-hor-center {
    0% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }
}

@-webkit-keyframes scale-out-horizontal {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
}

@keyframes scale-out-horizontal {
    0% {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
        opacity: 1;
    }

    100% {
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        opacity: 1;
    }
}

.change_color {
    background-color: transparent;
}

.scroll {
    background-color: #f1f1f1;
}

.navbar-light .navbar-toggler {
    color: transparent;
    border-color: transparent;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.active .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0,0,0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

/* HOMEPAGE */

.banner_home .h-100 {
    height: calc(100vh) !important;
    padding-top: 93px;
}

.h-100 {
    height: 100vh !important;
}

.video_container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.video_container video {
    min-width: 100%;
    min-height: 100%;
}

.video_overlay {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
}

.video_content {
    z-index: 2;
    color: #FFFFFF;
}

.video_content h1 {
    font-size: 2rem;
}

.video_content h2 {
    font-size: 3rem;
}

@media screen and (max-width: 450px) {
    .video_content h2 {
        font-size: 2rem !important;
    }
}

/* FOOTER */

.bg_blue {
    background-color: #212a38;
}

footer p {
    color: #9BA2AA;
}

footer p strong {
    color: #FFFFFF;
    text-transform: uppercase;
}

hr.white {
    border-top: 1px solid #FFFFFF;
}

footer a {
    color: #9BA2AA;
}

footer a:hover {
    color: #9BA2AA;
}

footer .btn-info {
    background-color: transparent !important;
    border-color: transparent !important;
    border: 0 !important;
    width: fit-content;
    padding: 0;
    text-transform: capitalize;
    color: #9BA2AA !important;
}

footer .btn-info:focus {
    box-shadow: none !important;
}

.tracking-in-expand {
    -webkit-animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
    animation: tracking-in-expand 0.7s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

@keyframes tracking-in-expand {
    0% {
        letter-spacing: -0.5em;
        opacity: 0;
    }

    40% {
        opacity: 0.6;
    }

    100% {
        opacity: 1;
    }
}

/* ABOUT US */

.banner_aboutUs {
    height: 70vh;
    padding-top: 93px;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./../images/images/campus.jpg");
    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.h-70 {
    height: calc(70vh - 93px);
}

/* PROJECTS */

.banner_projects {
    min-height: 100vh !important;
    /* padding-top: 93px; */
    background-image: url("./../images/images/dental_mockup.jpg");
    /* background-image: linear-gradient(rgba(48,56,69, 0.6), rgba(48,56,69, 0.6)), url("./../images/images/dental_mockup.jpg"); */
    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

@media screen and (min-width: 992px) {
    .banner_projects {
        padding-top: 93px;
    }
}

.mh-100 {
    min-height: 100vh;
}

.project_pills .nav-item a {
    height: 100px !important;
    width: 100px !important;
    border: 1px solid #FFFFFF !important;
    color: #FFFFFF;
}

.project_pills .nav-item a:hover {
    background-color: #FFFFFF;
    color: #000000;
    -webkit-transition: background-color color 0.3s ease-out;
    -moz-transition: background-color color 0.3s ease-out;
    -o-transition: background-color color 0.3s ease-out;
    transition: background-color color 0.3s ease-out;
}

.project_pills .nav-pills .nav-link.active,
.project_pills .nav-pills .show>.nav-link {
    color: #000000;
    background-color: #FFFFFF;
}

.carousel-item img {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e");
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e");
}

.carousel-indicators li {
    background-color: #000000 !important;
    width: 11px !important;
    height: 10px !important;
    border-radius: 50%;
}

.carousel-indicators {
    bottom: -55px;
}

/* BUTTONS */

.btn-info {
    background-color: #28a9fa;
    border-color: #28a9fa;
    width: 200px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    text-transform: uppercase;
}

.btn-info.login,
.btn-info.book {
    width: fit-content;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.btn-info:hover,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    background-color: #7159DB;
    border-color: #7159DB;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: none;
}

.btn-info:focus {
    color: #fff;
    background-color: #28a9fa;
    border-color: #28a9fa;
    box-shadow: 0 0 0 0.2rem rgb(97, 132, 231, 0.15);
}

.bg_image_contact {
    background-image: linear-gradient(rgba(48, 56, 69, 0.8), rgba(48, 56, 69, 0.8)), url("./../images/images/building.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.modal-60w {
    /* width: 60%;*/
    max-width: auto !important;
    max-height: none !important;
}

@media only screen and (min-width: 800px) {
    .modal-60w {
        max-width: 60vw !important;
    }
}

.modal-content label {
    width: 100%;
    text-align: left;
    color: #FFFFFF;
}

.content_contact {
    width: 100%;
}

.modal-80vh {
    min-height: 80vh;
}

.modal-content h2,
.modal-content p {
    color: #FFFFFF;
    text-align: left;
}

.modal-content .form-control {
    color: #FFFFFF;
    background-color: transparent;
    border: 0;
    border-bottom: 1px solid #FFFFFF;
    border-radius: 0;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.modal-content .form-control:focus {
    box-shadow: none;
}

.modal-content ::placeholder {
    color: #FFFFFF;
    opacity: 1;
    /* Firefox */
}

.modal-content :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #FFFFFF;
}

.modal-content ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #FFFFFF;
}

/* CONTACT */

header .btn-info.contact_button {
    background-color: transparent;
    border-color: transparent;
    width: fit-content;
    padding-top: 0;
    padding-bottom: 0;
    text-transform: capitalize;
    margin-top: 0 !important;
    border: 0;
}

header .btn-info.contact_button:hover,
header .btn-info.contact_button:not(:disabled):not(.disabled).active,
header .btn-info.contact_button:not(:disabled):not(.disabled):active,
.show>header .btn-info.contact_button.dropdown-toggle {
    background-color: transparent;
    border-color: transparent;
}

header .btn-info.contact_button:not(:disabled):not(.disabled).active:focus,
header .btn-info.contact_button:not(:disabled):not(.disabled):active:focus,
.show>header .btn-info.contact_button.dropdown-toggle:focus {
    box-shadow: none;
}

header .btn-info.contact_button:focus {
    color: #fff;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none !important;
}

footer .btn-info.contact_button:hover {
    text-decoration: underline;
}

.modal a {
    color: #FFFFFF;
}

.modal a:hover {
    text-decoration: none;
}

.modal-content {
    border: 0 !important;
}

/* BOOK A DEMO */

.bg_demo {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url("./../images/images/lines_abstract_bg.jpg");
    background-attachment: fixed;
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: calc(3rem + 93px) !important;
    min-height: 100vh;
}

.white {
    color: #FFFFFF !important;
}

.underline_title {
    text-decoration: underline;
    text-underline-offset: 10px;
    line-height: 50px;
}

.bg_demo .card {
    border-radius: 15px 15px 15px 15px;
    -webkit-border-radius: 15px 15px 15px 15px;
    -moz-border-radius: 15px 15px 15px 15px;
    padding: 30px 30px 30px 30px;
    box-shadow: 0 0 15px 0 #38eef4;
    -webkit-box-shadow: 0 0 15px 0 #38eef4;
    -moz-box-shadow: 0 0 15px 0 #38eef4;
    background-color: rgba(7, 21, 119, 0.4);
}

.bg_demo .card input,
.bg_demo .card textarea {
    border-radius: 20px;
}